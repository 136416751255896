import Icon from '@/components/ui/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { cn } from '@/utils';
function Info({
  className,
  icon,
  iconType,
  label,
  size,
  vertical,
  value,
  labelClasses,
  valueClasses,
  tooltipText,
  tooltipSide = 'top',
  children
}) {
  const classes = cn({
    info: true,
    'info-vertical': vertical,
    [size]: size,
    [className]: className
  });
  const labelClass = cn({
    'info-label': true,
    [labelClasses]: labelClasses
  });
  const valueClass = cn({
    'info-value': true,
    [valueClasses]: valueClasses
  });
  const InfoTooltip = ({
    tooltipText
  }) => {
    return <Tooltip data-sentry-element="Tooltip" data-sentry-component="InfoTooltip" data-sentry-source-file="Info.jsx">
        <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="Info.jsx">
          <Icon name="CircleHelp" data-sentry-element="Icon" data-sentry-source-file="Info.jsx" />
        </TooltipTrigger>
        <TooltipContent className="max-w-[280px]" side={tooltipSide} data-sentry-element="TooltipContent" data-sentry-source-file="Info.jsx">
          <span>{tooltipText}</span>
        </TooltipContent>
      </Tooltip>;
  };
  return <div className={classes} data-sentry-component="Info" data-sentry-source-file="Info.jsx">
      <span className={labelClass}>
        {icon && <Icon type={iconType} name={icon} />}
        {label}
        {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
      </span>
      <span className={valueClass}>
        {value}
        {children}
      </span>
    </div>;
}
export default Info;
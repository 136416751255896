import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
function Alert({
  icon,
  title,
  titleTag = 'h4',
  iconTitle,
  label,
  size,
  variant = 'primary',
  color,
  badge,
  cta,
  footer,
  inline,
  contentClasses,
  className,
  children
}) {
  const classes = cn({
    alert: true,
    [`alert-${variant}`]: variant && !color,
    'alert-inline': inline,
    'alert-icon': icon,
    [`bg-${color}-500/10`]: color,
    [`border-${color}-500/20`]: color,
    [size]: size,
    [className]: className
  });
  const contentClassName = cn({
    'alert-content': true,
    [`text-${color}-500`]: color,
    [contentClasses]: contentClasses
  });
  const AlertTitle = titleTag;
  return <div className={classes} role="alert" data-sentry-component="Alert" data-sentry-source-file="Alert.jsx">
      {title && <div className="alert-header">
          <AlertTitle>
            {badge && badge}
            {iconTitle && <Icon name={iconTitle} />}
            {title}
          </AlertTitle>
        </div>}

      <div className={contentClassName}>
        {icon && <Icon name={icon} />}
        {label && label}
        {children}
      </div>

      {cta && cta}

      {footer && <div className="alert-footer">{footer}</div>}
    </div>;
}
export default Alert;
import { useState } from 'react';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import Field from '@/components/ui/Field';
import { CURRENCY_SYMBOL } from '@/data';
import { useTranslations } from '@/hooks';
import { useDiscountStore } from '@/store';
import { cn } from '@/utils';
export default function DiscountForm({
  plan,
  price,
  className
}) {
  const [couponCode, setCouponCode] = useState('');
  const t = useTranslations();
  const {
    applyDiscountCode,
    applyDiscountError,
    canUseDiscount,
    clearDiscount,
    discount,
    isLoading
  } = useDiscountStore();
  const classes = cn({
    'discount-form': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="DiscountForm" data-sentry-source-file="DiscountForm.jsx">
      <div className="form-inline">
        <Field name="discount" value={couponCode} onChange={e => setCouponCode(e.target.value.toUpperCase())} placeholder="Coupon" data-sentry-element="Field" data-sentry-source-file="DiscountForm.jsx" />

        <Button label={t('common.actions.apply')} variant="light" loading={isLoading} onClick={() => applyDiscountCode(plan, price, couponCode)} data-sentry-element="Button" data-sentry-source-file="DiscountForm.jsx" />
      </div>

      {canUseDiscount && discount && discount.amount && <div className="col-span-2 mt-2">
          <Badge icon="Tag" variant="green-light" className="items-center" label={`${discount?.code} ${discount.amount}${discount.isPercent ? '%' : CURRENCY_SYMBOL}`} value={t('payment.coupon.applied')}>
            <Button size="sm" variant="link-plain" icon="X" loading={isLoading} className="ml-2" onClick={() => clearDiscount(plan, price)} />
          </Badge>
        </div>}

      {!canUseDiscount && applyDiscountError && applyDiscountError !== '' && <div className="col-span-2 mt-2">
          <Badge icon="Tag" className="items-center" variant="red-light" label={discount?.code} value={t(applyDiscountError)}>
            <Button size="sm" variant="link-plain" icon="X" loading={isLoading} className="ml-2" onClick={() => clearDiscount(plan, price)} />
          </Badge>
        </div>}
    </div>;
}
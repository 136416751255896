import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import PaymentSecure from './PaymentSecure';
import { matchfyApi } from '@/api';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import { APP_URL, ROUTES, STRIPE_PLAN_SUBSCRIPTIONS } from '@/data';
import { useLocale, useTranslations } from '@/hooks';
import { useAffiliateStore, useDiscountStore } from '@/store';
export default function StripeForm({
  amount,
  creditsScoreUsed,
  disabled,
  mode,
  plan,
  planName,
  payload,
  profile,
  extra
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const {
    affiliate
  } = useAffiliateStore();
  const {
    discount,
    canUseDiscount,
    discountAmount
  } = useDiscountStore();
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const returnUrl = `${APP_URL}/${locale}/${ROUTES.PAYMENT_REDIRECT}`;
  const handleError = error => {
    setLoading(false);
    setError(error.message);
  };
  const handleSubmit = async event => {
    setLoading(true);
    let customerId = profile?.customerId;
    event.preventDefault();
    const formattedAmount = parseFloat((amount / 100).toFixed(2));
    if (!stripe || !elements || !profile) {
      return;
    }
    try {
      const {
        error: submitError
      } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }
      if (!profile?.customerId) {
        const newCustomer = await matchfyApi.createCustomer();
        customerId = newCustomer.customerId;
      }
      const metadata = {
        amount,
        affiliateCode: affiliate?.code,
        payload: JSON.stringify(payload),
        plan: planName,
        formattedAmount,
        creditsScoreUsed,
        extra: JSON.stringify(extra)
      };
      if (canUseDiscount) {
        metadata.couponCode = JSON.stringify({
          code: discount.code,
          amount: discountAmount
        });
        await matchfyApi.useDiscount(discount.code, plan, amount);
      }
      await matchfyApi.undoPendingTransaction();
      if (mode === 'payment') {
        const {
          clientSecret
        } = await matchfyApi.createPaymentIntent(plan, customerId, metadata);
        await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: returnUrl
          },
          redirect: 'always'
        });
      }
      if (mode === 'subscription') {
        const subscription = await matchfyApi.createSubscription({
          customerId,
          plan: STRIPE_PLAN_SUBSCRIPTIONS[plan],
          metadata
        });
        const {
          type,
          clientSecret
        } = subscription;
        const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;
        await confirmIntent({
          elements,
          clientSecret,
          confirmParams: {
            return_url: returnUrl
          },
          redirect: 'always'
        });
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };
  return <form onSubmit={handleSubmit} data-sentry-component="StripeForm" data-sentry-source-file="StripeForm.jsx">
      <PaymentElement data-sentry-element="PaymentElement" data-sentry-source-file="StripeForm.jsx" />

      <div id="before-pay"></div>

      {error && <Alert variant="danger" className="mx-4 mb-4" label={error} />}

      <PaymentSecure className="pb-4" data-sentry-element="PaymentSecure" data-sentry-source-file="StripeForm.jsx" />

      <div className="payment-cta">
        <Button loading={loading} icon="CreditCard" label={t('payment.common.actions.pay')} type="submit" className="w-full" disabled={!stripe || disabled || loading} data-sentry-element="Button" data-sentry-source-file="StripeForm.jsx" />
      </div>
    </form>;
}